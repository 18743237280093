/* Contact Section Styling */
.contact-section {
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-image: url('./assets/5.jpg'); /* Path to your background image */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the background */
  background-repeat: no-repeat;
  position: relative; /* For overlay positioning */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Overlay */
.overlayc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Full section height */
  background: linear-gradient(135deg, rgba(0, 38, 91, 0.8), rgba(0, 23, 10, 0.8)); /* Gradient overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contact Content Styling */
.contact-content {
  color: white;
  text-align: center;
  max-width: 600px; /* Limit the width of the content */
}

/* Title */
.contact-title {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Contact Information */
.contact-info {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Form Styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

/* Input and Textarea */
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  outline: none;
}

/* Submit Button */
.submit-button {
  background-color: #046F32;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #005527;
}
