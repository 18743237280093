.about-container{
    display: flex;
    padding: 30px;
}

.about-container h1{
         padding: 10px;
       font-size: 3.5vw;
       
}


.vision-container{
    
    padding: 10px;
    width: 70%;
   
}
.about-text{
    width: 70%;
}

.mission-container{
    
 
    width: 80%;
   
}

.vision-container h2{
    font-size: 2.6vw;
    font-weight: 500;
}

.mission-container h2{
    font-size: 2.6vw;
    font-weight: 500;
}

.vision-container p{
    font-size: 1.5vw;
}

.mission-container p{
    font-size: 1.5vw;
}
.about-img{

    width: 20%;
}
.about-img img{
 width: 440px;
 height: 450px;
 margin-top: 50px;
 border-radius: 20px;
 border: 2px solid rgb(1, 1, 73);
}

@media (max-width: 768px){

    .about-container{
        display: flex;
        padding: 0 15px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .about-text{
        text-align: center;

        width: 100%;
    }
    .about-img{
        width: 100%;
        display: flex;
       align-items: center;
       justify-content: center;
    }
    .about-img img{
        width: 240px;
        height: 250px;
        margin-top: 50px;
        border-radius: 20px;
        border: 2px solid rgb(1, 1, 73);
        
       }
       .vision-container{
    
        width: 100%;
       
    }
    
    
    .mission-container{
        
     
        width: 100%;
       
    }

       .vision-container h1{
        font-size: 7vw;
        
        font-weight: 800;
    }
       .vision-container h2{
        font-size: 6.5vw;
        font-weight: 500;
    }
    
    .mission-container h2{
        font-size: 6.5vw;
        font-weight: 500;
    }
    
    .vision-container p{
        font-size: 4.5vw;
    }
    
    .mission-container p{
        font-size: 4.5vw;
    }


}