/* Membership Page Styling */
.membership-page {
    padding: 50px;
    background: linear-gradient(135deg, #00265B, #046F32); /* Dark blue to dark green gradient */
    color: white;
  }
  
  /* Membership Title */
  .membership-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Description */
  .membership-description {
    font-size: 1.5rem;
    text-align: center;
    max-width: 900px;
    margin: 0 auto 40px;
  }
  
  /* Membership Cards Container */
  .membership-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible grid layout */
    gap: 20px;
  }
  
  /* Individual Membership Card */
  .membership-card {
    background-color: white;
    color: #00265B;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  /* Card Hover Effect */
  .membership-card:hover {
    transform: translateY(-5px);
  }
  
  /* Membership Card Title */
  .membership-card h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #046F32;
  }
  
  /* Privileges List */
  .membership-card ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  /* Footer for How to Join and Support */
  .membership-footer {
    margin-top: 40px;
    text-align: center;
    font-size: 1.2rem;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .membership-footer h3 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .membership-page {
      padding: 20px;
      
    }
    .membership-title {
      font-size: 2.5rem;
    }
  
    .membership-description {
      font-size: 1.2rem;
    }
  
    .membership-card {
      padding: 15px;
    }
  
    .membership-footer {
      font-size: 1rem;
    }
  }
  