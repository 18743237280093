.form-section {
    padding: 40px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #dfdddd;
    text-align: left;
  }
  
  .form-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #00265B;
  }
  
  .embrace-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .embrace-form input, .embrace-form select, .embrace-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #00265B;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #004080;
  }
  
  @media screen and (max-width: 768px) {
    .form-section {
      margin: 20px 10px;
    }
    
   
    
  }