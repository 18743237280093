.embrace-section {
    padding: 40px;
    text-align: center;
    background-color: #f4f4f4;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #00265B;
    margin-bottom: 20px;
  }
  
  .quran-quote {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .embrace-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .embrace-list {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .form-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #00265B;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
  }